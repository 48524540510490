// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-application-modernization-credit-risk-transformation-js": () => import("./../../../src/pages/application-modernization/credit-risk-transformation.js" /* webpackChunkName: "component---src-pages-application-modernization-credit-risk-transformation-js" */),
  "component---src-pages-application-modernization-index-js": () => import("./../../../src/pages/application-modernization/index.js" /* webpackChunkName: "component---src-pages-application-modernization-index-js" */),
  "component---src-pages-application-modernization-modernization-is-a-journey-js": () => import("./../../../src/pages/application-modernization/modernization-is-a-journey.js" /* webpackChunkName: "component---src-pages-application-modernization-modernization-is-a-journey-js" */),
  "component---src-pages-application-modernization-prime-trade-modernization-js": () => import("./../../../src/pages/application-modernization/prime-trade-modernization.js" /* webpackChunkName: "component---src-pages-application-modernization-prime-trade-modernization-js" */),
  "component---src-pages-banking-and-capital-consolidated-audit-trail-js": () => import("./../../../src/pages/banking-and-capital/consolidated-audit-trail.js" /* webpackChunkName: "component---src-pages-banking-and-capital-consolidated-audit-trail-js" */),
  "component---src-pages-banking-and-capital-index-js": () => import("./../../../src/pages/banking-and-capital/index.js" /* webpackChunkName: "component---src-pages-banking-and-capital-index-js" */),
  "component---src-pages-banking-and-capital-loan-iq-js": () => import("./../../../src/pages/banking-and-capital/loan-iq.js" /* webpackChunkName: "component---src-pages-banking-and-capital-loan-iq-js" */),
  "component---src-pages-banking-and-capital-regulatory-reporting-as-a-service-js": () => import("./../../../src/pages/banking-and-capital/regulatory-reporting-as-a-service.js" /* webpackChunkName: "component---src-pages-banking-and-capital-regulatory-reporting-as-a-service-js" */),
  "component---src-pages-banking-and-capital-securities-financing-transaction-js": () => import("./../../../src/pages/banking-and-capital/securities-financing-transaction.js" /* webpackChunkName: "component---src-pages-banking-and-capital-securities-financing-transaction-js" */),
  "component---src-pages-business-process-benchmarking-js": () => import("./../../../src/pages/business-process/benchmarking.js" /* webpackChunkName: "component---src-pages-business-process-benchmarking-js" */),
  "component---src-pages-business-process-index-js": () => import("./../../../src/pages/business-process/index.js" /* webpackChunkName: "component---src-pages-business-process-index-js" */),
  "component---src-pages-business-process-solving-complex-business-challenges-js": () => import("./../../../src/pages/business-process/solving-complex-business-challenges.js" /* webpackChunkName: "component---src-pages-business-process-solving-complex-business-challenges-js" */),
  "component---src-pages-business-process-virtual-security-operations-center-support-js": () => import("./../../../src/pages/business-process/virtual-security-operations-center-support.js" /* webpackChunkName: "component---src-pages-business-process-virtual-security-operations-center-support-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-cloud-infrastructure-deploy-secrets-manager-azure-js": () => import("./../../../src/pages/cloud-infrastructure/deploy-secrets-manager-azure.js" /* webpackChunkName: "component---src-pages-cloud-infrastructure-deploy-secrets-manager-azure-js" */),
  "component---src-pages-cloud-infrastructure-hashicorp-vault-js": () => import("./../../../src/pages/cloud-infrastructure/hashicorp-vault.js" /* webpackChunkName: "component---src-pages-cloud-infrastructure-hashicorp-vault-js" */),
  "component---src-pages-cloud-infrastructure-hybrid-and-global-systems-js": () => import("./../../../src/pages/cloud-infrastructure/hybrid-and-global-systems.js" /* webpackChunkName: "component---src-pages-cloud-infrastructure-hybrid-and-global-systems-js" */),
  "component---src-pages-cloud-infrastructure-index-js": () => import("./../../../src/pages/cloud-infrastructure/index.js" /* webpackChunkName: "component---src-pages-cloud-infrastructure-index-js" */),
  "component---src-pages-cloud-infrastructure-large-global-bank-js": () => import("./../../../src/pages/cloud-infrastructure/large-global-bank.js" /* webpackChunkName: "component---src-pages-cloud-infrastructure-large-global-bank-js" */),
  "component---src-pages-cloud-infrastructure-large-global-enterprise-js": () => import("./../../../src/pages/cloud-infrastructure/large-global-enterprise.js" /* webpackChunkName: "component---src-pages-cloud-infrastructure-large-global-enterprise-js" */),
  "component---src-pages-cloud-infrastructure-opinionated-terraform-modules-and-blueprints-js": () => import("./../../../src/pages/cloud-infrastructure/opinionated-terraform-modules-and-blueprints.js" /* webpackChunkName: "component---src-pages-cloud-infrastructure-opinionated-terraform-modules-and-blueprints-js" */),
  "component---src-pages-cloud-solutions-amazon-web-services-to-google-cloud-platform-js": () => import("./../../../src/pages/cloud-solutions/amazon-web-services-to-google-cloud-platform.js" /* webpackChunkName: "component---src-pages-cloud-solutions-amazon-web-services-to-google-cloud-platform-js" */),
  "component---src-pages-cloud-solutions-cloud-approach-js": () => import("./../../../src/pages/cloud-solutions/cloud-approach.js" /* webpackChunkName: "component---src-pages-cloud-solutions-cloud-approach-js" */),
  "component---src-pages-cloud-solutions-four-phased-approach-js": () => import("./../../../src/pages/cloud-solutions/four-phased-approach.js" /* webpackChunkName: "component---src-pages-cloud-solutions-four-phased-approach-js" */),
  "component---src-pages-cloud-solutions-hcp-js": () => import("./../../../src/pages/cloud-solutions/hcp.js" /* webpackChunkName: "component---src-pages-cloud-solutions-hcp-js" */),
  "component---src-pages-cloud-solutions-index-js": () => import("./../../../src/pages/cloud-solutions/index.js" /* webpackChunkName: "component---src-pages-cloud-solutions-index-js" */),
  "component---src-pages-cloud-solutions-migration-factory-js": () => import("./../../../src/pages/cloud-solutions/migration-factory.js" /* webpackChunkName: "component---src-pages-cloud-solutions-migration-factory-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-data-science-aiml-js": () => import("./../../../src/pages/data-science/Aiml.js" /* webpackChunkName: "component---src-pages-data-science-aiml-js" */),
  "component---src-pages-data-science-data-management-and-governance-js": () => import("./../../../src/pages/data-science/data-management-and-governance.js" /* webpackChunkName: "component---src-pages-data-science-data-management-and-governance-js" */),
  "component---src-pages-data-science-document-data-extraction-and-analysis-js": () => import("./../../../src/pages/data-science/document-data-extraction-and-analysis.js" /* webpackChunkName: "component---src-pages-data-science-document-data-extraction-and-analysis-js" */),
  "component---src-pages-data-science-exposure-risk-analysis-solution-js": () => import("./../../../src/pages/data-science/exposure-risk-analysis-solution.js" /* webpackChunkName: "component---src-pages-data-science-exposure-risk-analysis-solution-js" */),
  "component---src-pages-data-science-index-js": () => import("./../../../src/pages/data-science/index.js" /* webpackChunkName: "component---src-pages-data-science-index-js" */),
  "component---src-pages-data-science-regulator-anomaly-detection-js": () => import("./../../../src/pages/data-science/regulator-anomaly-detection.js" /* webpackChunkName: "component---src-pages-data-science-regulator-anomaly-detection-js" */),
  "component---src-pages-enterprise-application-index-js": () => import("./../../../src/pages/enterprise-application/index.js" /* webpackChunkName: "component---src-pages-enterprise-application-index-js" */),
  "component---src-pages-google-cloud-service-building-landing-zone-in-gcp-js": () => import("./../../../src/pages/google-cloud-service/building-landing-zone-in-gcp.js" /* webpackChunkName: "component---src-pages-google-cloud-service-building-landing-zone-in-gcp-js" */),
  "component---src-pages-google-cloud-service-index-js": () => import("./../../../src/pages/google-cloud-service/index.js" /* webpackChunkName: "component---src-pages-google-cloud-service-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-life-at-atyeti-js": () => import("./../../../src/pages/life-at-atyeti.js" /* webpackChunkName: "component---src-pages-life-at-atyeti-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-total-quality-management-index-js": () => import("./../../../src/pages/total-quality-management/index.js" /* webpackChunkName: "component---src-pages-total-quality-management-index-js" */),
  "component---src-pages-total-quality-management-quality-engineering-js": () => import("./../../../src/pages/total-quality-management/quality-engineering.js" /* webpackChunkName: "component---src-pages-total-quality-management-quality-engineering-js" */)
}

